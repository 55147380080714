<template>
  <li class="d-flex flex-row justify-content-between">
    <div class="left w-50">
      <div class="d-flex gap-2 align-items-center mb-2">
        <div :class="['status-indicator', statusIndicator]" :title="statusMessage" />
        <h3 class="m-0">{{ profile.title }}</h3>
        <!-- Добавляем сообщение о статусе -->
        <div v-if="statusMessage" :class="[statusMessageClass]">
          {{ statusMessage }}
        </div>
        <!-- Проверяем, есть ли выбранный аккаунт, и отображаем его -->
        <div v-if="profile.selectedAccount" class="gray small">
          <small>{{ profile.selectedAccount }}</small>
        </div>
      </div>
      <div class="statues">
        <div class="status-ban">
          <strong>Статус бана:</strong>
          <!-- Статусы бана обновляются для выбранного аккаунта -->
          <div v-for="status in profile.statuses" :key="status.type" class="status-item">
            <span>{{ status.type }} - {{ status.message }}</span>
          </div>
          <div v-if="!profile.statuses || profile.statuses.length === 0">
            <span>Нет статусов бана</span>
          </div>
        </div>
        <div class="status-notifications">
          <strong>Уведомления:</strong>
          <!-- Уведомления обновляются для выбранного аккаунта -->
          <div v-if="profile.notifications && profile.notifications.length">
            <div v-for="notification in profile.notifications" :key="notification" class="notification-item">
              <span>{{ notification }}</span>
            </div>
          </div>
          <div v-else>
            <span>Нет уведомлений</span>
          </div>
        </div>
        <div class="status-appilki">
          <strong>Статус апелляции:</strong>
          <div v-if="profile.appealStatus">
            <div class="appeal-status" :class="getAppealStatusClass(profile.appealStatus)">
              <span v-if="profile.appealDate">
                {{ formatDateWithTime(profile.appealDate) }} - {{ formatAppealStatus(profile.appealStatus) }}
              </span>
              <span v-else>
                {{ formatAppealStatus(profile.appealStatus) }}
              </span>
              <span v-if="profile.appealId" class="appeal-id">(ID: {{ profile.appealId }})</span>
            </div>
          </div>
          <div v-else>
            <span>Нет апелляций</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right w-50 d-flex flex-column justify-content-between">
      <div class="d-flex gap-2">
        <div class="d-flex align-items-center gap-2">
          <small><strong>Proxy:</strong></small>
          <ProxySelector 
            :profile-uuid="profile.uuid" 
            :current-proxy="profile.proxy"
            @proxy-updated="handleProxyUpdate"
            @proxy-update-error="handleProxyError"
          />
        </div>
        <span class="small-text">
          <small><strong>UUID:</strong> <strong>{{ profile.uuid }}</strong></small>
          <small><strong>Tags:</strong> <strong>{{ profile.tags.join(', ') }}</strong></small>
          <small><strong>Last Active:</strong> <strong>{{ profile.last_active || 'Не запускался' }}</strong></small>
        </span>
        
      </div>
      <div v-if="profile.accounts && profile.accounts.length" class="account-selection d-flex flex-column gap-1 align-items-end">
        <label for="account-select">Номер кабинета:</label>
        <select v-model="selectedAccount" id="account-select" class="form-select w-25" @change="handleAccountChange">
          <option v-for="account in profile.accounts" :key="account" :value="account">{{ account }}</option>
        </select>
      </div>
      <div class="profile-actions ms-auto gap-2 d-flex flex-wrap">
        <button @click="openAppilkaPopup" class="btn btn-primary">Аппилка #1</button>
        <button
            @click="updateProfileStatus(profile.uuid)"
            class="btn btn-warning"
            :disabled="isUpdating"
        >
          Обновить статусы
        </button>
      </div>
    </div>

    <!-- Попап для формы аппеляции -->
    <PopupForm v-if="showAppilkaPopup" 
               :formData="formData" 
               :selectedAccount="selectedAccount" 
               :selectedLang="selectedLang"
               :initialBanType="getBanSummary()"
               @close="showAppilkaPopup = false"
               @submit="submitAppilkaForm"
               @reload="reloadData"
               @updateLang="updateLang"
               @banTypeChange="updateBanType" />
  </li>
</template>

<script>
import PopupForm from './PopupForm.vue';
import ProxySelector from './ProxySelector.vue';
import axios from 'axios';

export default {
  name: 'UserProfile',
  components: { PopupForm, ProxySelector },
  props: ['profile'],
  emits: ['update-status', 'run-appilka', 'fetchProfiles', 'update-appeal-status', 'update-accounts', 'update-profile-data'],
  data() {
    return {
      selectedAccount: null,
      selectedLang: 'en',
      showAppilkaPopup: false,
      currentBanType: null,
      formData: {
        customerId: '',
        advertisingBusiness: '',
        whoPays: '',
        businessDescription: '',
        clientAgency: '',
        website: '',
        keywords: '',
        domainOwnership: '',
        issueSummary: '',
        askFeedback: true,
        receiveEmailAfterClose: true
      },
      statusIndicator: '',
      isUpdating: false,
      statusMessage: '',
      statusMessageClass: '',
      isSubmitting: false,
      submitTimeout: null
    };
  },
  mounted() {
    // Инициализируем выбранный аккаунт
    if (this.profile.selectedAccount) {
      this.selectedAccount = this.profile.selectedAccount;
    } else if (this.profile.accounts && this.profile.accounts.length) {
      this.selectedAccount = this.profile.accounts[0];
      
      // Обновляем данные профиля с выбранным аккаунтом
      this.$emit('update-profile-data', {
        uuid: this.profile.uuid,
        updates: {
          selectedAccount: this.selectedAccount
        }
      });
    }
    
    // Выводим отладочную информацию
    console.log('Профиль при монтировании:', this.profile);
    console.log('Выбранный аккаунт:', this.selectedAccount);
    console.log('Данные аккаунтов:', this.profile.accountsData);
    
    // Загружаем данные выбранного аккаунта при монтировании
    if (this.selectedAccount) {
      this.handleAccountChange();
    }
  },
  methods: {
    handleProxyUpdate() {
      this.statusIndicator = 'blinking-green';
      this.statusMessage = 'Прокси успешно обновлен';
      this.statusMessageClass = 'success-message';
      setTimeout(() => {
        this.statusIndicator = '';
        this.statusMessage = '';
        this.statusMessageClass = '';
      }, 3000);
      this.$emit('fetchProfiles');
    },
    handleProxyError(error) {
      this.statusIndicator = 'blinking-red';
      this.statusMessage = `Ошибка при обновлении прокси: ${error.message}`;
      this.statusMessageClass = 'error-message';
      setTimeout(() => {
        this.statusIndicator = '';
        this.statusMessage = '';
        this.statusMessageClass = '';
      }, 5000);
    },
    openAppilkaPopup() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      // Устанавливаем начальный тип бана только если он еще не установлен
      if (!this.currentBanType) {
        this.currentBanType = this.getBanSummary();
      }

      // Загружаем данные при открытии поппа с учетом текущего языка
      axios.get('/api/templates/by-language', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { language: this.selectedLang }
      })
      .then(response => {
        const templates = response.data;

        const getRandomAnswer = (question) => {
          const matchingTemplate = templates.find((t) => t.question === question);
          if (matchingTemplate && matchingTemplate.answers) {
            const answersArray = matchingTemplate.answers.split('\n');
            const answer = answersArray[Math.floor(Math.random() * answersArray.length)].trim();
            
            // Если это запрос для типа бана, логируем информацию
            if (question === this.currentBanType) {
              console.log('Определен тип бана:', this.currentBanType);
              console.log('Выбран текст для бана:', answer);
            }
            
            return answer;
          }
          return '';
        };

        // Подставляем ответы в поля формы
        this.formData = {
          ...this.formData,
          website: 'https://youtube.com',
          keywords: 'video',
          customerId: this.selectedAccount,
          whoPays: getRandomAnswer('Who pays for the Google Ads account(s)?'),
          businessDescription: getRandomAnswer('Please provide a brief description of your business'),
          clientAgency: getRandomAnswer('Information regarding client-agency relationship'),
          domainOwnership: getRandomAnswer('Domain ownership'),
          issueSummary: getRandomAnswer(this.currentBanType),
          askFeedback: true,
          receiveEmailAfterClose: true
        };

        this.showAppilkaPopup = true;
      })
      .catch(error => {
        console.error('Ошибка получения данных:', error);
        this.statusIndicator = 'blinking-red';
        this.statusMessage = `Ошибка: ${error.message || 'Не удалось загрузить шаблоны'}`;
        this.statusMessageClass = 'error-message';
      });
    },
    reloadData(banType) {
      if (banType) {
        this.currentBanType = banType;
      }
      this.openAppilkaPopup();
    },
    updateLang(newLang) {
      this.selectedLang = newLang;
      this.reloadData();
    },
    geoCode() {
      const geoMatch = this.profile.title.match(/\((\w{2})\)/);
      return geoMatch ? geoMatch[1] : null;
    },

    getBanSummary() {
      if (this.profile.statuses.some(status => status.type === 'ОС')) {
        return 'бан ОС';
      } else if (this.profile.statuses.some(status => status.type === 'ПП')) {
        return 'бан ПП';
      } else {
        return 'бан общий';
      }
    },
    runAppilka(uuid, appilkaNumber) {
      this.$emit('run-appilka', uuid, appilkaNumber, this.selectedAccount);
    },
    updateProfileStatus(uuid) {
      if (this.isUpdating) return;
      
      this.isUpdating = true;
      this.statusIndicator = 'blinking-orange';
      this.statusMessage = 'Обновление статусов...';
      this.statusMessageClass = '';

      const handleSuccess = (response) => {
        // Обновляем список аккаунтов, если они пришли в ответе
        if (response.accounts) {
          // Вместо прямого изменения пропса, эмитим событие
          this.$emit('update-profile-data', {
            uuid: this.profile.uuid,
            updates: {
              accounts: response.accounts,
              statuses: response.statuses || this.profile.statuses,
              notifications: response.notifications || this.profile.notifications
            }
          });
          
          // Если есть выбранный аккаунт в ответе, используем его
          if (response.selectedAccount) {
            this.selectedAccount = response.selectedAccount;
          }
          // Если нет выбранного аккаунта или его нет в новом списке, выбираем первый
          else if (!this.selectedAccount || !response.accounts.includes(this.selectedAccount)) {
            this.selectedAccount = response.accounts[0];
          }

          // Эмитим событие для обновления аккаунтов в родительском компоненте
          this.$emit('update-accounts', {
            uuid: this.profile.uuid,
            accounts: response.accounts
          });
        }

        // Если нет выбранного аккаунта, показываем предупреждение
        if (!this.selectedAccount) {
          this.statusIndicator = 'blinking-orange';
          this.statusMessage = 'Выберите аккаунт и запустите обновление';
          this.statusMessageClass = 'warning-message';
        } else {
          this.statusIndicator = 'blinking-green';
          this.statusMessage = 'Статусы успешно обновлены';
          this.statusMessageClass = 'success-message';
        }
        
        this.isUpdating = false;
      };

      const handleError = (error) => {
        console.error('Ошибка при обновлении статусов:', error);
        this.statusIndicator = 'blinking-red';
        this.statusMessage = `Ошибка: ${error.message || 'Не удалось обновить статусы'}`;
        this.statusMessageClass = 'error-message';
        this.isUpdating = false;
      };

      // Эмитим событие update-status
      this.$emit('update-status', uuid, this.selectedAccount, {
        onSuccess: handleSuccess,
        onError: handleError
      });
    },
    submitAppilkaForm(formData) {
      if (this.isSubmitting) {
        console.log('Уже идет отправка апелляции');
        return;
      }
      
      this.isSubmitting = true;
      this.showAppilkaPopup = false;
      this.statusIndicator = 'blinking-orange';
      this.statusMessage = 'Отправка апелляции...';
      this.statusMessageClass = '';

      const token = localStorage.getItem('token');
      if (!token) {
        this.handleError(new Error('Не найден токен авторизации'));
        return;
      }

      const uuid = this.profile.uuid;
      const updatedFormData = { ...formData, geoCode: this.geoCode() };

      axios.post(`/api/appeal/${uuid}/submit-appeal`, updatedFormData, {
        headers: { 'Authorization': `Bearer ${token}` },
        retry: 0, // Отключаем повторные попытки для этого запроса
        timeout: 600000 // 10 минут
      })
      .then(response => {
        // Создаем лог с текущей датой и статусом
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });
        
        let appealLog = '';
        let appealStatus = '';
        
        if (response.data.status === 'verification_required') {
          this.statusIndicator = 'blinking-orange';
          this.statusMessage = 'Требуется верификация аккаунта';
          this.statusMessageClass = 'warning-message';
          
          appealStatus = 'verification_required';
          appealLog = `${formattedDate} - Требуется верификация аккаунта`;
          
          this.$emit('update-appeal-status', {
            uuid: this.profile.uuid,
            appealStatus: appealStatus,
            appealLogs: [appealLog],
            accountId: formData.customerId // Добавляем ID аккаунта
          });
        }
        else if (response.data.status === 'in_review') {
          this.statusIndicator = 'blinking-green';
          this.statusMessage = 'Апелляция уже находится на рассмотрении';
          this.statusMessageClass = 'success-message';
          
          appealStatus = 'in_review';
          appealLog = `${formattedDate} - Апелляция находится на рассмотрении`;
          
          this.$emit('update-appeal-status', {
            uuid: this.profile.uuid,
            appealId: response.data.appealId,
            appealStatus: appealStatus,
            appealDate: response.data.appealDate,
            appealLogs: [appealLog],
            accountId: formData.customerId // Добавляем ID аккаунта
          });
        } else if (response.data.status === 'submitted') {
          this.statusIndicator = 'blinking-green';
          this.statusMessage = 'Апелляция успешно отправлена';
          this.statusMessageClass = 'success-message';
          
          appealStatus = 'submitted';
          appealLog = `${formattedDate} - Апелляция успешно отправлена`;
          
          this.$emit('update-appeal-status', {
            uuid: this.profile.uuid,
            appealStatus: appealStatus,
            appealDate: response.data.appealDate,
            appealLogs: [appealLog],
            accountId: formData.customerId // Добавляем ID аккаунта
          });
        }
        
        this.$emit('fetchProfiles');
      })
      .catch(error => {
        console.error('Ошибка при отправке апелляции:', error);
        this.statusIndicator = 'blinking-red';
        this.statusMessage = `Ошибка: ${error.response?.data?.message || error.message || 'Не удалось отправить апелляцию'}`;
        this.statusMessageClass = 'error-message';
      })
      .finally(() => {
        this.isSubmitting = false;
      });
    },
    handleError(error) {
      console.error(error);
      this.statusIndicator = 'blinking-red';
      this.statusMessage = `Ошибка: ${error.message}`;
      this.statusMessageClass = 'error-message';
      this.isSubmitting = false;
    },
    formatAppealStatus(status) {
      const statusMap = {
        'in_review': 'На рассмотрении',
        'rejected': 'Отклонена',
        'approved': 'Одобрена',
        'submitted': 'Отправлена',
        'verification_required': 'Требуется верификация'
      };
      return statusMap[status] || status;
    },

    getAppealStatusClass(status) {
      const classMap = {
        'in_review': 'appeal-status-review',
        'rejected': 'appeal-status-rejected',
        'approved': 'appeal-status-approved',
        'submitted': 'appeal-status-submitted',
        'verification_required': 'appeal-status-verification'
      };
      return classMap[status] || '';
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },

    formatDateWithTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      });
    },

    updateBanType(newType) {
      this.currentBanType = newType;
    },

    handleAccountChange() {
      if (!this.selectedAccount) return;
      
      this.statusIndicator = 'blinking-orange';
      this.statusMessage = 'Загрузка данных аккаунта...';
      this.statusMessageClass = '';
      
      const token = localStorage.getItem('token');
      if (!token) {
        this.statusIndicator = 'blinking-red';
        this.statusMessage = 'Ошибка: Не найден токен авторизации';
        this.statusMessageClass = 'error-message';
        return;
      }
      
      axios.get(`/api/profile-actions/${this.profile.uuid}/account-data/${this.selectedAccount}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(response => {
        console.log('Получены данные аккаунта:', response.data);
        
        // Обновляем данные профиля
        this.$emit('update-profile-data', {
          uuid: this.profile.uuid,
          updates: {
            selectedAccount: this.selectedAccount,
            statuses: response.data.statuses || [],
            notifications: response.data.notifications || [],
            appealStatus: response.data.appealStatus,
            appealId: response.data.appealId,
            appealDate: response.data.appealDate
          }
        });
        
        this.statusIndicator = 'blinking-green';
        this.statusMessage = 'Данные аккаунта загружены';
        this.statusMessageClass = 'success-message';
        
        // Через 3 секунды убираем сообщение
        setTimeout(() => {
          this.statusIndicator = '';
          this.statusMessage = '';
          this.statusMessageClass = '';
        }, 3000);
      })
      .catch(error => {
        console.error('Ошибка при получении данных аккаунта:', error);
        this.statusIndicator = 'blinking-red';
        this.statusMessage = `Ошибка: ${error.response?.data?.message || 'Не удалось получить данные аккаунта'}`;
        this.statusMessageClass = 'error-message';
      });
    },

    // Добавляем метод для копирования данных апелляции из общего профиля в выбранный аккаунт
    copyAppealDataToAccount() {
      if (!this.selectedAccount) {
        this.statusIndicator = 'blinking-red';
        this.statusMessage = 'Ошибка: Не выбран аккаунт';
        this.statusMessageClass = 'error-message';
        return;
      }
      
      if (!this.profile.appealStatus && !this.profile.appealLogs) {
        this.statusIndicator = 'blinking-red';
        this.statusMessage = 'Ошибка: Нет данных апелляции для копирования';
        this.statusMessageClass = 'error-message';
        return;
      }
      
      this.statusIndicator = 'blinking-orange';
      this.statusMessage = 'Копирование данных апелляции...';
      this.statusMessageClass = '';
      
      // Эмитим событие для обновления статуса апелляции
      this.$emit('update-appeal-status', {
        uuid: this.profile.uuid,
        appealStatus: this.profile.appealStatus,
        appealId: this.profile.appealId,
        appealDate: this.profile.appealDate,
        appealLogs: this.profile.appealLogs,
        accountId: this.selectedAccount
      });
      
      this.statusIndicator = 'blinking-green';
      this.statusMessage = 'Данные апелляции скопированы';
      this.statusMessageClass = 'success-message';
      
      // Через 3 секунды убираем сообщение
      setTimeout(() => {
        this.statusIndicator = '';
        this.statusMessage = '';
        this.statusMessageClass = '';
      }, 3000);
    }
  }
};
</script>
<style scoped>
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.blinking-orange {
  background-color: orange;
  animation: blink 1s infinite;
}

.blinking-green {
  background-color: green;
  animation: blink 1s infinite;
}

.blinking-red {
  background-color: red;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

.error-message {
  color: red;
  font-size: 0.9em;
}

.success-message {
  color: green;
  font-size: 0.9em;
}

.warning-message {
  color: orange;
  font-size: 0.9em;
}

.small-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.status-item, .notification-item {
  margin: 5px 0;
}

.appeal-status {
  display: flex;
  gap: 10px;
  align-items: center;
}

.appeal-id, .appeal-date {
  color: #666;
  font-size: 0.9em;
}

.gray {
  color: #666;
}

.small {
  font-size: 0.9em;
}
</style>