import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // Импортируем маршруты
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';  // Подключаем стили Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Подключаем скрипты Bootstrap (например, для модальных окон)
import './assets/styles.css';  // Подключаем глобальные стили

// Глобальная конфигурация axios
axios.defaults.timeout = 600000; // 10 минут
axios.defaults.retry = 0; // Отключаем повторные попытки
axios.defaults.retryDelay = 1000;

const app = createApp(App);
app.use(router);  // Подключаем маршрутизацию
app.mount('#app');  // Монтируем приложение в DOM
