<template>
  <div class="proxy-selector">
    <select v-model="selectedProxy" class="form-select" @change="handleProxyChange">
      <option v-for="proxy in proxies" :key="proxy.uuid" :value="proxy">
        {{ proxy.title ? proxy.title : `${proxy.host}:${proxy.port}` }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProxySelector',
  props: {
    profileUuid: {
      type: String,
      required: true
    },
    currentProxy: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      proxies: [],
      selectedProxy: null
    };
  },
  methods: {
    async fetchProxies() {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        const response = await axios.get('/api/proxies/all', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.proxies = response.data;
        
        // Если есть текущий прокси, выбираем его
        if (this.currentProxy && this.currentProxy.uuid) {
          this.selectedProxy = this.proxies.find(p => p.uuid === this.currentProxy.uuid) || this.proxies[0];
        } else if (this.proxies.length > 0) {
          // Если нет текущего прокси, выбираем первый
          
          // Добавляем currentProxy в список, если его там нет
          this.proxies.push({
            ...this.currentProxy,
            // title: this.currentProxy.title || `${this.currentProxy.host}:${this.currentProxy.port}`
          });
          this.selectedProxy = this.currentProxy;
          // console.log(this.selectedProxy);
        }
      } catch (error) {
        console.error('Ошибка при получении списка прокси:', error);
      }
    },
    async handleProxyChange() {
      if (!this.selectedProxy) return;

      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        console.log('Отправляем запрос на обновление прокси:', {
          profileUuid: this.profileUuid,
          proxyUuid: this.selectedProxy.uuid
        });

        const response = await axios.post(`/api/profile-actions/${this.profileUuid}/update-proxy`, {
          proxyUuid: this.selectedProxy.uuid
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log('Ответ от сервера:', response.data);
        this.$emit('proxy-updated', response.data.proxy);
      } catch (error) {
        console.error('Ошибка при обновлении прокси:', error);
        const errorMessage = error.response?.data?.message || error.response?.data?.error || error.message;
        this.$emit('proxy-update-error', { message: errorMessage });
      }
    }
  },
  watch: {
    currentProxy: {
      immediate: true,
      handler(newProxy) {
        if (newProxy && newProxy.uuid && this.proxies.length > 0) {
          this.selectedProxy = this.proxies.find(p => p.uuid === newProxy.uuid) || this.proxies[0];
        }
      }
    }
  },
  mounted() {
    this.fetchProxies();
  }
};
</script>

<style scoped>
.proxy-selector {
  display: inline-block;
  min-width: 200px;
}

.form-select {
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
</style> 